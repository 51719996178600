<template>
  <div
    class="overflow-y-auto fixed top-0 right-0 bottom-0 left-0 w-full h-full flex justify-center align-center items-center bg-transparant-black z-10"
  >
    <div
      class="text-right block search-modal rounded-sm shadow bg-white p-8 w-11/12 overflow-y-auto m-auto"
    >
      <p class="text-center font-bold text-xl mb-12">البحث عن منتج معين</p>
      <div>
        <b-field label="البحث باستخدام">
          <b-select
            placeholder="اختر"
            v-model="byCriterion"
            @input="updateCriterion"
            class="text-right"
          >
            <option v-for="criterion in criteria" :value="criterion.value" :key="criterion.value">
              {{ criterion.name }}
            </option>
          </b-select>
        </b-field>

        <b-field v-if="byCriterion === 'category'" label="اسم الفئة">
          <b-select
            placeholder="اختر فئةً"
            v-model="category"
            class="text-right"
            :loading="fetchingCategories"
          >
            <option v-for="category in categories" :key="category.key" :value="category.key">
              {{ category.name }}
            </option>
          </b-select>
        </b-field>

        <b-field v-if="byCriterion === 'name'" label="اسم المنتج">
          <!-- <b-input v-model.trim="name" expanded></b-input> -->
          <b-input v-model="name" expanded></b-input>
        </b-field>

        <b-field v-if="byCriterion === 'tags'" label="الوسم">
          <b-input v-model="tags" expanded></b-input>
        </b-field>
      </div>

      <div class="items-table overflow-y-auto ltr mt-4 relative">
        <div v-if="items != null && items.length > 0 && !searching">
          <div
            class="item flex items-center content-center rtl"
            v-for="item in items"
            :key="item.id"
          >
            <div class="item-image flex justify-center items-center content-center">
              <img :src="item.image" alt="" />
            </div>
            <p class="item-name mr-8">{{ item.name }}</p>
            <span class="spacer"></span>
            <div class="control-container">
              <button class="m-edit" data="تعديل" @click="$emit('editItem', item.id)">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
          </div>
        </div>
        <div
          v-else-if="!searching"
          class="flex items-center content-center justify-center empty-items"
        >
          لا يوجد عاصر
        </div>
        <div v-if="searching" class="loader-container relative">
          <loadingSpinner :size="100" color="#007AFE" class="loading-icon"></loadingSpinner>
        </div>
      </div>

      <div class="flex justify-center items-center content-center">
        <button class="mujeeb-button" @click="$emit('close')">إغلاق</button>
      </div>
    </div>
  </div>
</template>
<script>
import debounce from "debounce";
import loadingSpinner from "./../../loading/SpringSpinner";
export default {
  components: {
    loadingSpinner
  },
  data() {
    return {
      items: null,
      searching: false,
      criteria: [
        {
          name: "اسم المنتج",
          value: "name"
        },
        {
          name: "وسم",
          value: "tags"
        },
        {
          name: "فئة المنتج",
          value: "category"
        }
      ],
      fetchingCategories: false,
      byCriterion: "name",
      name: null,
      tags: null,
      category: null,
      categories: null
    };
  },
  methods: {
    search() {
      this.searching = true;
      this.$api
        .customRequest({
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/items/search`,
          method: "get",
          params: {
            criterion: this.byCriterion,
            value: this[this.byCriterion]
          }
        })
        .then(({ data }) => {
          console.log("done fetching");
          console.log(data);
          this.items = data.items;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.searching = false;
        });
    },
    updateCriterion(criterion) {
      this.byCriterion = criterion;
      if (criterion === "category") {
        this.fetchCategory();
      }
      this.resetState();
    },
    fetchCategory() {
      this.fetchingCategories = true;
      this.$api
        .customRequest({
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/categories`,
          method: "get",
          responsType: "json"
        })
        .then(({ data }) => {
          console.log(data);
          this.categories = data.categories.filter(
            cat => cat.id !== `root${this.$store.getters["page/id"]}`
          );
          // this.categories = this.flatCategories(data.categories);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.fetchingCategories = false;
        });
    },
    resetState() {
      this.name = null;
      this.tag = null;
      this.category = null;
    }
  },
  watch: {
    category: function(newVal) {
      if (newVal != null && newVal != "") {
        this.category = newVal;
        this.search();
      }
    },
    tags: debounce(function(newTags) {
      if (newTags != null && newTags != "") {
        this.tags = newTags;
        this.search();
      }
    }, 800),
    name: debounce(function(newName) {
      if (newName != null && newName != "") {
        this.name = newName;
        this.search();
      }
    }, 800)
  }
};
</script>

<style lang="scss" scoped>
.search-modal {
  background: white;
  max-height: 100%;
  .items-table {
    max-height: 400px;
    padding: 15px 5px;
    .item {
      // border: 1px solid rgb(214, 214, 214);
      box-shadow: $shadow-md;
      border-radius: 6px;
      padding: 5px 5px 5px 10px;
      height: 60px;
      margin-bottom: 5px;
    }
    .loading-icon {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }
    .empty-items {
      height: 150px;
      border: 1px dashed rgb(207, 207, 207);
    }
    .item-image {
      width: 100px;
      height: 100px;
      flex: 0 0 100px;
      img {
        border-radius: 6px;
        object-fit: scale-down;
        height: 50px;
        width: auto;
      }
    }
    .control-container {
      button {
        height: 35px;
        width: 35px;
        border-radius: 6px;
        &.m-edit {
          background: #d9f9f5;
          svg {
            color: dodgerblue;
          }
        }
      }
    }
    .loader-container {
      height: 150px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 660px) {
  .items-table {
    .item {
      display: block;
      text-align: center;
      height: auto !important;
      .item-image {
        width: 100% !important;
        text-align: center;
        img {
          height: 80px !important;
        }
      }
      p {
        margin: 10px 0px;
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          height: 1px;
          width: 100%;
          background: rgba(235, 235, 235, 0.685);
          top: 100%;
        }
      }
      .control-container {
        padding: 10px;
        button {
          display: inline-block !important;
          height: auto;
          width: auto;
          padding: 10px 10px;
          &::before {
            content: attr(data);
            display: inline;
          }
        }
      }
    }
  }
}
</style>
