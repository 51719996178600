<template>
  <div class="products-table">
    <loading-spinner v-if="loading" position="absolute"></loading-spinner>

    <p class="m-section-title">{{ $store.getters["recipesConfig/titles"].itemsTable }}</p>
    <div id="PRODUCTS_new_product_button">
      <button class="mujeeb-button icon-on-right green" @click="onAddNewProduct">
        {{ $store.getters["recipesConfig/buttons"].itemAddition }}<font-awesome-icon icon="plus" />
      </button>
      <button class="mujeeb-button icon-on-right green" @click="openSearchModal">
        ابحث<font-awesome-icon icon="search" />
      </button>
    </div>

    <div class="m-items-table" v-if="productsArray != null && productsArray.length > 0">
      <div class="thead">
        <div class="tr">
          <div
            class="th"
            v-for="(property, index) in $store.getters['recipesConfig/itemsProps']"
            :class="getCssClass(property.cssClass)"
            :key="index"
          >
            {{ property.title }}
          </div>
        </div>
      </div>

      <div class="tbody">
        <div class="tr" v-for="(product, index) in productsArray" :key="index">
          <div
            class="td"
            v-for="(property, index) in $store.getters['recipesConfig/itemsProps']"
            :key="index"
            :data="property.title"
            :class="getCssClass(property.cssClass)"
          >
            <img v-if="property.name === 'image'" :src="product.image" />
            <span v-else-if="property.name === 'name'">{{ product.name }}</span>
            <span v-else-if="property.name === 'category'">
              <span v-for="cat in product.categories" :key="cat.id">{{ cat.name }}</span>
            </span>
            <span v-else-if="property.name === 'variations'">{{
              product.variations == null || product.variations.length === 0 ? "لايوجد" : "يوجد"
            }}</span>
            <b-switch
              v-else-if="property.name === 'published'"
              :value="product.published"
              v-model="product.published"
              @input="confirmMakeItemInvisible($event, product)"
            ></b-switch>
            <template v-else>
              <button class="m-edit" data="تعديل" @click="editProduct(product.id)">
                <font-awesome-icon icon="edit" />
              </button>
              <button class="m-delete" data="حذف" @click="confirmDeletion(product)">
                <font-awesome-icon icon="trash-alt" />
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="border: 1px dashed black; border-radius: 6px;">
      <p class="empty-table-msg">
        {{ `لم يتم إدخال ${this.$store.getters["recipesConfig/recipeItems"]} حتى الآن` }}
      </p>
    </div>
    <div class="pagination-control">
      <button
        class="mujeeb-button"
        v-if="paginationStates[paginationState].next"
        @click="requestItemsPage"
      >
        <font-awesome-icon class="left-margin" icon="chevron-right" />التالي
      </button>
      <button
        class="mujeeb-button"
        v-if="paginationStates[paginationState].back"
        @click="requestItemsPage(false)"
      >
        السابق<font-awesome-icon class="right-margin" icon="chevron-left" />
      </button>
    </div>

    <searchModal
      v-if="showSearchModal"
      @close="showSearchModal = false"
      @editItem="onEditItem"
    ></searchModal>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import PaginationMixin from "../../../mixins/pagination";
import searchModal from "./searchModal";
export default {
  name: "products",
  mixins: [PaginationMixin()],
  components: {
    searchModal
  },
  data() {
    return {
      showSearchModal: false,
      confirmationMessages: {
        delete: {
          store: "هل أنت متاكد من رغبتك بحذف هذا المنتج؟",
          services: "هل أنت متاكد من رغبتك بحذف هذه الخدمة؟"
        },
        makeInvisible: {
          store: "هل أنت متاكد من رغبتك بإخفاء هذا المنتج؟",
          services: "هل أنت متاكد من رغبتك بإخفاء هذه الخدمة؟"
        }
      }
    };
  },
  methods: {
    confirmMakeItemInvisible(value, item) {
      this.$buefy.dialog.confirm({
        title: `إخفاء ${item.name}؟`,
        message: this.confirmationMessages.makeInvisible[this.$store.getters["page/botRecipe"]],
        confirmText: "متأكد",
        cancelText: "كلا",
        type: "is-danger",
        onConfirm: () => {
          this.onPublishedStatusChanged(value, item);
        },
        onCancel: () => {
          item.published = !value;
        }
      });
    },
    onPublishedStatusChanged(value, item) {
      this.$api
        .customRequest({
          method: "put",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/items/${item.id}/published`,

          responseType: "json",
          data: {
            value: value
          }
        })
        .then(({ data }) => {
          this.$buefy.toast.open({
            duration: 3000,
            message: `تم ${value ? "إظهار" : "إخفاء"} المنتج`,
            position: "is-top",
            type: "is-success"
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    editProduct(id) {
      this.productId = id;
      this.$router.push({ name: "chatbot.items.item", params: { itemId: id } });
    },
    confirmDeletion(item) {
      this.$buefy.dialog.confirm({
        title: `حذف ${item.name}؟`,
        message: this.confirmationMessages.delete[this.$store.getters["page/botRecipe"]],
        confirmText: "متأكد",
        cancelText: "كلا",
        type: "is-danger",
        onConfirm: () => {
          this.deleteProduct(item.id);
        }
      });
    },
    deleteProduct(id) {
      this.loading = true;
      this.$api
        .customRequest({
          method: "delete",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/items/${id}`,

          responseType: "json"
        })
        .then(({ data }) => {
          this.$buefy.toast.open({
            duration: 3000,
            message: "تم حذف المنتج",
            position: "is-top",
            type: "is-success"
          });
          this.$store.dispatch("itemsTable/resetPointers");
          this.requestItemsPage();
        })
        .catch(error => {
          console.log(error);
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ أثناء حذف المنتج",
            position: "is-top",
            type: "is-يشىلثق"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onAddNewProduct() {
      this.$router.push({ name: "chatbot.items.item" });
    },
    getCssClass(cssClass) {
      const temp = {};
      temp[`item-width-${this.$store.getters["recipesConfig/itemsProps"].length}`] = true;
      if (cssClass) {
        temp[cssClass] = true;
      }
      return temp;
    },
    openSearchModal() {
      this.showSearchModal = true;
    },
    onEditItem(itemId) {
      this.showSearchModal = false;
      this.productId = itemId;
      this.$router.push({ name: "chatbot.items.item", params: { itemId: itemId } });
    }
  }
};
</script>

<style scoped lang="scss">
.products-table {
  direction: rtl;
  #PRODUCTS_new_product_button {
    margin-bottom: 40px;
  }
  .m-section-title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin: 20px auto 40px auto;
  }
  .pagination-control {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    button {
      &:first-child {
        i {
          margin-left: 10px;
        }
      }
      &:last-child {
        i {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
